import React, { useContext } from 'react';
import { Switch, FormControlLabel, Button } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { ThemeContext } from '../../ThemeContext';
import { styled } from '@mui/system';

// Benutzerdefinierter Switch-Button mit angepassten Farben
const CustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        color: '#2196F3', // Blaue Farbe im inaktiven Zustand
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#2196F3', // Blaue Farbe im aktiven Zustand
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#BBDEFB', // Leicht bläuliche Hintergrundfarbe im inaktiven Zustand
    },
    '& .MuiSwitch-track.Mui-checked': {
        backgroundColor: '#2196F3', // Blaue Hintergrundfarbe im aktiven Zustand
    },
}));

const AdminDashboardHeader = ({ onLogout }) => {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext);

    return (
        <div style={{ maxWidth: "100%", overflowX: "auto", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                    control={
                        <CustomSwitch
                            checked={darkMode} // Zustand des Switches aus dem Kontext
                            onChange={toggleDarkMode}
                            icon={
                                <WbSunnyIcon
                                    style={{
                                        maxWidth: "100%",
                                        overflowX: "auto",
                                        transform: 'translateY(-3px)',  // Icon leicht nach oben verschieben
                                    }}
                                />
                            }
                            checkedIcon={
                                <DarkModeIcon
                                    style={{
                                        maxWidth: "100%",
                                        overflowX: "auto",
                                        transform: 'translateY(-3px)'  // Icon leicht nach oben verschieben
                                    }}
                                />
                            }
                        />
                    }
                    label="" // Kein Label erforderlich, nur der Switch mit den Icons
                />

                {/* Logout-Button */}
                <Button
                    onClick={onLogout} // Aufruf der onLogout Funktion
                    color="secondary"
                    variant="contained"
                    style={{ marginLeft: '16px' }} // Abstand zwischen Switch und Button
                >
                    Logout
                </Button>
            </div>
        </div>
    );
};

export default AdminDashboardHeader;