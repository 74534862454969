import React, { useContext } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { WbSunny as LightIcon, DarkMode as DarkIcon } from '@mui/icons-material';
import { ThemeContext } from '../../ThemeContext'; // Beispiel für Theme-Kontext (je nach Implementierung)
import { styled } from '@mui/system';

// Benutzerdefinierter Switch-Button mit angepassten Farben
const CustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        color: '#2196F3', // Blaue Farbe im inaktiven Zustand
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#2196F3', // Blaue Farbe im aktiven Zustand
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#BBDEFB', // Leicht bläuliche Hintergrundfarbe im inaktiven Zustand
    },
    '& .MuiSwitch-track.Mui-checked': {
        backgroundColor: '#2196F3', // Blaue Hintergrundfarbe im aktiven Zustand
    },
}));

const LoginHeader = () => {
    const { darkMode, toggleDarkMode } = useContext(ThemeContext); // Dark Mode Zustand und Funktion

    return (
        <div style={{
            maxWidth: "100%",
            overflowX: "auto",
            display: 'flex',
            justifyContent: 'space-between', // Elemente gleichmäßig verteilen
            alignItems: 'center',
            padding: '10px 20px'  // Padding für Abstand
        }}>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>  {/* Der Switch ist nun rechts */}
                <FormControlLabel
                    control={
                        <CustomSwitch
                            checked={darkMode}
                            onChange={toggleDarkMode}
                            icon={<LightIcon />}
                            checkedIcon={<DarkIcon />}
                        />
                    }
                    label="" // Kein Text, nur der Schalter
                />
            </div>
        </div>
    );
};

export default LoginHeader;