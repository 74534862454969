import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, TextField, Button, Typography, Alert, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import LoginHeader from '../dashboards/headers/LoginHeader';
import {loginUser} from "../api/data";

const Login = () => {
    const [mode, setMode] = useState('view');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async () => {
        try {
            const response = await loginUser({username, password});

            if (response.user.role === 'admin') {
                navigate('/admin-dashboard');
            } else {
                navigate('/user-dashboard');
            }
        } catch (err) {
            setError('Invalid username or password');
        }
    };

    return (
        <Container style={{ maxWidth: "100%", height: "100vh", display: 'flex', flexDirection: 'column' }}>
            <LoginHeader mode={mode} setMode={setMode} />
            <Grid container style={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Paper elevation={3} style={{ padding: '30px', width: '100%' }}>
                        <Typography variant="h5" component="h2" align="center" gutterBottom>
                            Login
                        </Typography>
                        <form
                            noValidate
                            autoComplete="off"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleLogin();
                            }}
                            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
                        >
                            <TextField
                                label="Username"
                                variant="outlined"
                                fullWidth
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                variant="outlined"
                                fullWidth
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                            >
                                Login
                            </Button>
                            {error && <Alert severity="error">{error}</Alert>}
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Login;